import React from 'react';
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const AustralianResources = () => {
	return (
		<Layout>
            <Seo title="Australian Resources" />

			<h1 className="title">Australian Resources</h1>

            <SearchInput />
            
			<table>
                <tbody>
                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">IP Australia</td>
                    </tr>

                    <tr>
                        <td>AusPat (patent search)</td>
                        <td><a target="_blank" rel="noreferrer" href="http://pericles.ipaustralia.gov.au/ols/auspat/">http://pericles.ipaustralia.gov.au/ols/auspat/</a></td>
                    </tr>

                    <tr>
                        <td>Australian trade mark search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://search.ipaustralia.gov.au/trademarks/search/quick">https://search.ipaustralia.gov.au/trademarks/search/quick</a></td>
                    </tr>

                    <tr>
                        <td>Australian trade mark classification search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://tmgns.search.ipaustralia.gov.au/">https://tmgns.search.ipaustralia.gov.au/</a></td>
                    </tr>

                    <tr>
                        <td>IP Australia log in</td>
                        <td><a target="_blank" rel="noreferrer" href="https://portal.ipaustralia.gov.au/login">https://portal.ipaustralia.gov.au/login</a></td>
                    </tr>

                    <tr>
                        <td>Objective Connect (for IP Australia hearings)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://secure.objectiveconnect.com/login">https://secure.objectiveconnect.com/login</a></td>
                    </tr>

                    <tr>
                        <td>Official Journal of Patents</td>
                        <td><a target="_blank" rel="noreferrer" href="https://secure.objectiveconnect.com/login">https://secure.objectiveconnect.com/login</a></td>
                    </tr>

                    <tr>
                        <td>Patent Manual of Practice and Procedure</td>
                        <td><a target="_blank" rel="noreferrer" href="https://manuals.ipaustralia.gov.au/patent">https://manuals.ipaustralia.gov.au/patent</a></td>
                    </tr>

                    <tr>
                        <td>Trade Marks Manual of Practice and Procedure</td>
                        <td><a target="_blank" rel="noreferrer" href="https://manuals.ipaustralia.gov.au/trademark">https://manuals.ipaustralia.gov.au/trademark</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Legislation</td>
                    </tr>

                    <tr className="thead subtitle">
                        <td colSpan="2">IP Legislation</td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Patents Act 1990 </td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/C2019C00088">https://www.legislation.gov.au/Details/C2019C00088</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Patents Regulations 1991</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2017C00128">https://www.legislation.gov.au/Details/F2017C00128</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Intellectual Property Legislation Amendment (Raising the Bar) Regulation 2013 (No. 1)   </td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2013L00479">https://www.legislation.gov.au/Details/F2013L00479</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Trade Marks Act 1995</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/C2004A04969"> https://www.legislation.gov.au/Series/C2004A04969</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Trade Marks Regulations 1995</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2020C00950"> https://www.legislation.gov.au/Details/F2020C00950</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Copyright Act 1968</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/C1968A00063"> https://www.legislation.gov.au/Series/C1968A00063</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Copyright Regulations 2017</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2019C00032"> https://www.legislation.gov.au/Details/F2019C00032</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Designs Act 2003</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/C2004A01232"> https://www.legislation.gov.au/Series/C2004A01232</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Designs Regulations 2004</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2021C01143"> https://www.legislation.gov.au/Details/F2021C01143</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Plant Breeder's Rights Act 1994</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/C2004A04783"> https://www.legislation.gov.au/Series/C2004A04783</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead subtitle">
                        <td colSpan="2">Court Legislation</td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Federal Court Rules 2011</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2019C00426"> https://www.legislation.gov.au/Details/F2019C00426</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Federal Court of Australia Intellectual Property Practice Notes</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fedcourt.gov.au/law-and-practice/national-practice-areas/ip"> https://www.fedcourt.gov.au/law-and-practice/national-practice-areas/ip</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Federal Court forms</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fedcourt.gov.au/forms-and-fees/forms/federal-court-rules"> https://www.fedcourt.gov.au/forms-and-fees/forms/federal-court-rules</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Uniform Civil Procedure Rules 2005 (NSW)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://legislation.nsw.gov.au/view/html/inforce/current/sl-2005-0418"> https://legislation.nsw.gov.au/view/html/inforce/current/sl-2005-0418</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>High Court Rules 2004</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/F2020C00055"> https://www.legislation.gov.au/Details/F2020C00055</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Evidence Act 1995</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Details/C2021C00472"> https://www.legislation.gov.au/Details/C2021C00472</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Supreme Court of NSW Practice Notes</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.supremecourt.justice.nsw.gov.au/judgments/publications/practice-notes"> https://www.supremecourt.justice.nsw.gov.au/judgments/publications/practice-notes</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Supreme Court of NSW forms</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.ucprforms.nsw.gov.au/"> https://www.ucprforms.nsw.gov.au/</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Supreme Court (General Civil Procedure) Rules 2015 (Vic)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.vic.gov.au/in-force/statutory-rules/supreme-court-general-civil-procedure-rules-2015/040"> https://www.legislation.vic.gov.au/in-force/statutory-rules/supreme-court-general-civil-procedure-rules-2015/040</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Supreme Court of Victoria Practice Notes</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.supremecourt.vic.gov.au/law-and-practice/practice-notes"> https://www.supremecourt.vic.gov.au/law-and-practice/practice-notes</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Supreme Court of Victoria forms, fees and services</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.supremecourt.vic.gov.au/forms-fees-and-services"> https://www.supremecourt.vic.gov.au/forms-fees-and-services</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead subtitle">
                        <td colSpan="2">Pharma legislation</td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Therapeutic Goods Act 1989</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/C2004A03952"> https://www.legislation.gov.au/Series/C2004A03952</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Therapeutics Goods Regulations 1990</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/F1996B00406"> https://www.legislation.gov.au/Series/F1996B00406</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Therapeutic Goods (Medical Devices) Regulations 2002</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.legislation.gov.au/Series/F2002B00237"> https://www.legislation.gov.au/Series/F2002B00237</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Australian regulatory guidelines for medical devices (ARGMD)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.tga.gov.au/publication/australian-regulatory-guidelines-medical-devices-argmd"> https://www.tga.gov.au/publication/australian-regulatory-guidelines-medical-devices-argmd</a></td>
                    </tr>

                    {/* HEADING */}
                    
                    <tr className="thead title">
                        <td colSpan="2">Case law & resources</td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Federal Court of Australia</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fedcourt.gov.au/digital-law-library/judgments/search"> https://www.fedcourt.gov.au/digital-law-library/judgments/search</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Australasian Legal Information Institute (AustLII)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.austlii.edu.au/"> https://www.austlii.edu.au/</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Jade</td>
                        <td><a target="_blank" rel="noreferrer" href="https://jade.io/"> https://jade.io/</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Commonwealth Court Portal</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.comcourts.gov.au/pas/fed_esearch"> https://www.comcourts.gov.au/pas/fed_esearch</a></td>
                    </tr>

                    <tr className="has-subtitle">
                        <td>Federal Court daily lists</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.fedcourt.gov.au/court-calendar/daily-court-lists"> https://www.fedcourt.gov.au/court-calendar/daily-court-lists</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">TGA & PBS resources</td>
                    </tr>

                    <tr>
                        <td>ARTG search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://tga-search.clients.funnelback.com/s/search.html?query=&collection=tga-artg"> https://tga-search.clients.funnelback.com/s/search.html?query=&collection=tga-artg</a></td>
                    </tr>

                    <tr>
                        <td>TGA registration of new chemical entities</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.tga.gov.au/prescription-medicines-registration-new-chemical-entities-australia"> https://www.tga.gov.au/prescription-medicines-registration-new-chemical-entities-australia</a></td>
                    </tr>

                    <tr>
                        <td>TGA registration of new generic medicines and biosimilar medicines</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.tga.gov.au/prescription-medicines-registration-new-generic-medicines-and-biosimilar-medicines"> https://www.tga.gov.au/prescription-medicines-registration-new-generic-medicines-and-biosimilar-medicines</a></td>
                    </tr>

                    <tr>
                        <td>Department of Health biosimilar medicines approved by the TGA</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www1.health.gov.au/internet/main/publishing.nsf/content/biosimilar-which-medicines-are-available-in-australia"> https://www1.health.gov.au/internet/main/publishing.nsf/content/biosimilar-which-medicines-are-available-in-australia</a></td>
                    </tr>

                    <tr>
                        <td>PBS medicine search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.pbs.gov.au/pbs/home"> https://www.pbs.gov.au/pbs/home</a></td>
                    </tr>

                    <tr>
                        <td>PBS medicine status lookup</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.pbs.gov.au/medicinestatus/home.html"> https://www.pbs.gov.au/medicinestatus/home.html</a></td>
                    </tr>

                    <tr>
                        <td>PBS prescription expenditure reports</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.pbs.gov.au/info/statistics/expenditure-prescriptions/pbs-expenditure-and-prescriptions"> https://www.pbs.gov.au/info/statistics/expenditure-prescriptions/pbs-expenditure-and-prescriptions</a></td>
                    </tr>

                    <tr>
                        <td>PBS Item Reports</td>
                        <td><a target="_blank" rel="noreferrer" href="http://medicarestatistics.humanservices.gov.au/statistics/pbs_item.jsp"> http://medicarestatistics.humanservices.gov.au/statistics/pbs_item.jsp</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">PBS calendar</td>
                    </tr>

                    <tr>
                        <td>TGA & PBS resources</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.pbs.gov.au/info/industry/useful-resources/pbs-calendar"> https://www.pbs.gov.au/info/industry/useful-resources/pbs-calendar</a></td>
                    </tr>

                </tbody>
			</table>
		</Layout>
	)
}

export default AustralianResources